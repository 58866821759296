<template>
  <div style="background:#ffffff;">
    <div class="first-screen">
      <div class="container">
        <div class="inner">
          <div class="text">
            <h1 class="animate__animated animate__fadeInDown">PackingGo</h1>
            <p class="desc animate__animated animate__fadeInRight">
              判可狗<br>
              国内首款针对完整包装解决方案（CPS）的行业通用软件
            </p>
            <a href="javascript:;" @click="$router.push('/AiSolution/home')"
               class="text-btn animate__animated animate__fadeInUp">开启您的专属AI方案</a>
          </div>
          <LoginFrom v-if="!isLogin"></LoginFrom>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="section about-wrap" style="padding-top:40px;">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            关于我们/About us
          </div>
          <div class="desc wow  animate__fadeInUp">
            <p>我们是一家专注于开发及运营中国大陆地区“CPS法运输包装工程设计”行业通用软件的公司，该软件的算法及行业应用技术由东莞优纳伯包装技术研究所协同</p>
          </div>
          <div class="aboutus">
            <Row>
              <Col span="12">
              <img src="@/assets/images/img_aboutus_02.png" alt="">
              </Col>
              <Col span="12">
              <div class="aboutus-item">
                <img src="@/assets/images/bg_aboutus_01.svg" alt="" style="width:80px;position: absolute;top:0;left:0">
                <div class="aboutus-title wow  animate__fadeInDown">便捷的系统</div>
                <p class="aboutus-desc wow animate__fadeInRight">软件适用于运输包装行业，本质上属于一个互联网人工智能替代的远程支持系统</p>
              </div>
              </Col>
              <Col span="12">
              <div class="aboutus-item">
                <img src="@/assets/images/bg_aboutus_02.svg" alt="" style="width:80px;position: absolute;bottom:0;right:0">
                <div class="aboutus-title wow  animate__fadeInDown">高效的服务</div>
                <p class="aboutus-desc wow  animate__fadeInLeft">
                  软件应用对象包括各行业包装工程师，并能帮助包装工程师提升CPS设计能力；软件输出包装方案，含图纸、包装说明书模板，使工作效能提升近十倍</p>
              </div>
              </Col>
              <Col span="12">
              <img src="@/assets/images/img_aboutus_01.png" alt="">
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品功能/Functions  -->
    <div class=" section product-function">
      <div class="container">
        <div class="container-inner">
          <div class="title wow animate__fadeIn">
            产品功能/Functions
          </div>
          <div class="desc wow animate__fadeInUp">
            <p>我们是一家专注于开发及运营中国大陆地区“CPS法运输包装工程设计”行业通用软件的公司，该软件的算法及行业应用技术由东莞优纳伯包装技术研究所协同</p>
          </div>
          <div style="margin-top: 60px;">
            <Row :gutter="50">
              <Col span="8">
              <div class="function-item wow animate__zoomIn bg1">
                <div class="pic">
                  <div class="func-icon">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ic_functions_01"></use>
                    </svg>
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">一键生成包装方案</div>
                  <p class="meta-desc">为您提供最便捷的寻找包装方案的渠道，只要输入产品的相关信息，即可一键生成包装方案。</p>
                </div>
              </div>
              </Col>
              <Col span="8">
              <div class="function-item wow animate__zoomIn">
                <div class="pic">
                  <div class="func-icon">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ic_functions_02"></use>
                    </svg>
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">产品安全到达</div>
                  <p class="meta-desc">为您寻找最合适的包装材料，降低包装物流供应链总成本，使产品安全到达用户手中。</p>
                </div>
              </div>
              </Col>
              <Col span="8">
              <div class="function-item wow animate__zoomIn bg2">
                <div class="pic">
                  <div class="func-icon">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-ic_functions_03"></use>
                    </svg>
                  </div>
                </div>
                <div class="function-meta">
                  <div class="meta-title">节约运输成本</div>
                  <p class="meta-desc">为您设计最优化的运输方式，大幅度提高产品的堆放密度，节省单件运输成本。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品特点/Features -->
    <div class=" section product-features">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            产品特点/Features
          </div>
          <div class="desc wow  animate__fadeInUp">
            <p>我们是一家专注于开发及运营中国大陆地区“CPS法运输包装工程设计”行业通用软件的公司，该软件的算法及行业应用技术由东莞优纳伯包装技术研究所协同</p>
          </div>
          <div style="margin-top: 60px;">
            <div class="features-wrap">
              <div class="features-item" :class="{'is-active':activeIndex === 0}" @mouseenter="activeIndex = 0">
                <div class="features-item-inner">
                  <i class="iconfont icon-ic_features_01"></i>
                </div>
              </div>
              <div class="features-item" :class="{'is-active':activeIndex === 1}" @mouseenter="activeIndex = 1">
                <div class="features-item-inner"> <i class="iconfont icon-ic_features_02"></i></div>
              </div>
              <div class="features-item" :class="{'is-active':activeIndex === 2}" @mouseenter="activeIndex = 2">
                <div class="features-item-inner"> <i class="iconfont icon-ic_features_03"></i></div>
              </div>
              <div class="features-item" :class="{'is-active':activeIndex === 3}" @mouseenter="activeIndex = 3">
                <div class="features-item-inner"> <i class="iconfont icon-ic_features_04"></i></div>
              </div>
              <div class="features-item" :class="{'is-active':activeIndex === 4}" @mouseenter="activeIndex = 4">
                <div class="features-item-inner"> <i class="iconfont icon-ic_features_05"></i></div>
              </div>
            </div>
            <div class="features-intro">
              <div class="top-border"></div>
              <div class="intro-inner">
                <template v-if="activeIndex === 0">
                  <div class="feat-name">适用广阔的市场</div>
                  <p>适用于国内运输包装工程方案设计的行业通用软件，应用对象有：</p>
                  <p>各行业在职非专业包装工程师：<br />
                    各行业在职专业包装工程师：从传统包装工程专业设计能力提升至专业CPS法包装工程设计能力<br />
                    各高校包装专业或物流专业教师及学生</p>
                </template>
                <template v-if="activeIndex === 1">
                  <div class="feat-name">设计/Design</div>
                  <p>CPS采用系统工程的科学方法，包装供应商向用户提供从包装咨询、设计、制造、工艺、运输、仓储、回收方案设计及发运直到产品安全到达目的地的一整套系统服务，具备完整性，系统性，全面性。</p>
                </template>
                <template v-if="activeIndex === 2">
                  <div class="feat-name">算法/Algorithm</div>
                  <p>PackingGo是国内首款针对运输包装工程设计的“CPS法运输包装工程设计”行业通用软件</p>
                  <p>设计团队由一流的软件工程师和包装工程师组成<br />
                    PackingGo算法从上万个案例中归纳得到<br />
                    PackingGo也是国内首款行业应用软件</p>
                </template>
                <template v-if="activeIndex === 3">
                  <div class="feat-name">深度赋能/Capability</div>
                  <p>PackingGo本质上属于一个互联网人工智能替代的远程支持系统，具有以下功能</p>
                  <p>帮助各行业在职专业或非专业包装工程师提升CPS设计能力<br />
                    产品输出方案图纸及包装说明书，可使工作效能提升近十倍</p>
                </template>
                <template v-if="activeIndex === 4">
                  <div class="feat-name">专业方案/Plan</div>
                  <p>此系统通过使用者进行模板化的简单需求输入，自动导出包装方案的 规格、材质、结构、成本<br />
                    同时输出可用于执行的图纸、三维效果图及作业指导书指引相关方后续的包装及各使用环节的作业<br />
                    产品末端保留自定义方案输出界面，支持该产品未来无限上行</p>
                </template>
              </div>
            </div>
            <div style="height: 30px;"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 适用行业/Applicable -->
    <div class="section applicable">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            适用行业/Applicable
          </div>
          <div class="desc wow  animate__fadeInUp">
            <p>我们是一家专注于开发及运营中国大陆地区“CPS法运输包装工程设计”行业通用软件的公司，该软件的算法及行业应用技术由东莞优纳伯包装技术研究所协同</p>
          </div>
          <div style="margin-top: 60px;" class="wow  animate__zoomIn">
            <Row :gutter="1">
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_01.png" alt="">
              <p class="text">
                医疗·金融·工业制造等智能机柜
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_02.png" alt="">
              <p class="text">
                固体·流体等流体抗外鼓包装
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_03.png" alt="">
              <p class="text">
                军用包装
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_04.png" alt="">
              <p class="text">
                汽车及航空部件·代步器材器具
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_05.png" alt="">
              <p class="text">
                汽车及航空部件·代步器材器具
              </p>
              </Col>
              <Col :span="8" class="applicable-item">
              <img src="@/assets/images/img_applicable_06.png" alt="">
              <p class="text">
                ‘一高一大’整体或集体包装
              </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴/Customers -->
    <div class="section customers">
      <div class="container">
        <div class="container-inner">
          <div class="title wow  animate__fadeIn">
            合作伙伴/Customers
          </div>
          <div class="desc wow  animate__fadeInUp">
            <p>我们是一家专注于开发及运营中国大陆地区“CPS法运输包装工程设计”行业通用软件的公司，该软件的算法及行业应用技术由东莞优纳伯包装技术研究所协同</p>
          </div>
          <div style="margin-top: 60px;">
            <Row>
              <Col :span="6">
              <img src="@/assets/images/img_customers_01.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_02.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_03.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_04.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_05.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_06.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_07.png" style="max-width: 100%;" alt="">
              </Col>
              <Col :span="6">
              <img src="@/assets/images/img_customers_08.png" style="max-width: 100%;" alt="">
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>

    <BackTop>
      <div class="top">
        <Icon type="md-arrow-up" />
      </div>
      返回顶部
    </BackTop>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import LoginFrom from './components/LoginFrom'
import { mapGetters } from 'vuex'
export default {
  components: {
    LoginFrom
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    ...mapGetters(['isLogin'])
  },
  mounted () {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true
    })
    wow.init()
  }
}
</script>

<style lang="scss" scoped>
.first-screen {
    position: relative;
    height: 680px;
    background: url(../../assets/images/bg_header.png) no-repeat center;
    background-size: 100% 100%;
    color: #ffffff;
    z-index: 0;
    overflow: hidden;
    &::after {
        content: '';
        width: 100%;
        height: 141px;
        position: absolute;
        bottom: 0px;
        background: url(../../assets/images/bg_introduction_01.png) no-repeat center;
        background-position: center bottom;
        background-size: contain;
    }
    .inner {
        position: relative;
        width: 930px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .text {
        margin-top: 90px;
        width: 450px;
        h1 {
            font-weight: bold;
            font-size: 64px !important;
            line-height: 96px;
        }
        .desc {
            font-size: 24px;
            line-height: 34px;
            color: #ffffff;
            border-left: 1px solid #ffffff;
            padding-left: 5px;
        }
        .text-btn {
            display: block;
            margin-top: 40px;
            width: 160px;
            height: 48px;
            line-height: 48px;
            background: #ffffff;
            border-radius: 10px;
            font-size: 14px;
            text-align: center;
            color: #5fb5d9;
        }
    }
}

.section {
    background: #ffffff;
    .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #272727;
        margin-bottom: 20px;
    }
    .desc {
        width: 782px;
        margin: 0 auto;
        letter-spacing: 0.05em;
        color: #8d8d8d;
        text-align: center;
    }
}

.aboutus {
    margin-top: 60px;
    img {
        width: 100%;
        line-height: 1;
    }
    &-item {
        width: 330px;
        height: 100%;
        line-height: 1;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-title {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
        margin-bottom: 10px;
    }
    &-desc {
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #8d8d8d;
        font-size: 14px;
    }
    /deep/ .ivu-col {
        font-size: 0px;
    }
}

.product-function {
    margin-top: 40px;
    padding: 200px 0 200px;
    background: rgba(229, 229, 229, 0.6) url(../../assets/images/bg_functions.png) no-repeat left;
    background-size: contain;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::before {
        content: '';
        width: 100%;
        height: 141px;
        position: absolute;
        top: 0px;
        background: url(../../assets/images/bg_introduction_02.png) no-repeat center;
        background-size: contain;
        background-position: center top;
    }
    .function-item {
        position: relative;
        width: 100%;
        height: 445px;
        background: #67c5ec;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &.bg1 {
            background: #67c5ec url(../../assets/images/bg_functioncard_01.svg) no-repeat top left;
        }
        &.bg2 {
            background: #67c5ec url(../../assets/images/bg_functioncard_03.svg) no-repeat center;
        }
        .pic {
            flex: 1;
            display: flex;
            align-items: center;
            text-align: center;
            .func-icon {
                margin: 0 auto;
                width: 154px;
                height: 154px;
                background: #99e1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                font-size: 100px;
            }
        }
        .function-meta {
            position: relative;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 140px;
            background: #ffffff;
            border-radius: 0px 0px 5px 5px;
            padding: 20px;
            .meta-title {
                font-size: 18px;
                margin-bottom: 5px;
                text-align: center;
                color: #000000;
            }
            .meta-desc {
                color: #8d8d8d;
            }
            &::before {
                position: absolute;
                top: 0px;
                left: 50%;
                margin-left: -10px;
                content: ' ';
                width: 20px;
                height: 20px;
                border-width: 10px 10px 10px 10px;
                border-style: solid;
                border-color: #67c5ec transparent transparent transparent;
            }
        }
    }
}
.product-features {
    padding: 50px 0;
    background: url(../../assets/images/bg_features_02.svg) no-repeat;
    background-position: 0px 150px;
    .features-wrap {
        display: flex;
        align-items: center;
        .features-item {
            position: relative;
            flex: 1;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            &::after {
                display: none;
                position: absolute;
                content: ' ';
                border-style: solid;
                border-width: 20px;
                left: 50%;
                margin-left: -20px;
                border-color: transparent transparent #67c5ec transparent;
            }
            &-inner {
                display: flex;
                margin: 0 auto;
                width: 170px;
                height: 170px;
                align-items: center;
                justify-content: center;
                background: #67c5ec;
                border-radius: 100%;
                border: 20px solid #ffffff;
                color: #ffffff;
                .iconfont {
                    font-size: 92px;
                }
            }
            &.is-active,
            &:hover {
                &::after {
                    display: block;
                }
                .features-item-inner {
                    background: #ffffff;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: #67c5ec;
                    .iconfont {
                        font-size: 82px;
                    }
                }
            }
        }
    }
    .features-intro {
        margin: 30px 10px;
        .top-border {
            height: 25px;
            background: #67c5ec;
        }
        .intro-inner {
            width: 100%;
            height: 185px;
            padding: 15px 40px;
            background: #ffffff;
            box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
            .feat-name {
                border-left: 4px solid #67c5ec;
                padding-left: 10px;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.05em;
                color: #000000;
            }
            p {
                margin-top: 15px;
                padding-left: 14px;
            }
        }
    }
}

.applicable {
    padding: 50px 0;
    margin-bottom: 80px;
    background: url(../../assets/images/bg_features_01.svg) no-repeat;
    background-position: right bottom;
    .applicable-item {
        position: relative;
        margin-bottom: 2px;
        width: 100%;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            transition: all 0.2s ease;
            opacity: 0.7;
        }
        &:hover {
            img {
                transform: scale(1.2);
                opacity: 1;
            }
        }
        .text {
            width: 120px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -60px;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.2em;
            line-height: 20px;
            color: #ffffff;
        }
    }
}

.customers {
    padding: 50px 0;
}

.network {
    background: #eeeeee;
    // background: url(../../assets/images/img-map.svg) no-repeat;
    .container {
        height: 770px;
        background: url(../../assets/images/img_map.png) no-repeat center;
        background-size: 100%;
        background-position-y: -1px;
    }
    h3 {
        text-align: center;
        font-size: 38px;
        margin-bottom: 30px;
    }
    p {
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #8d8d8d;
        font-size: 14px;
    }
}

.top {
    width: 42px;
    height: 42px;
    margin: 0 auto;
    border-radius: 50%;
    background: #67c5ec;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}
/deep/ .ivu-back-top i {
    padding: 8px 0;
}
</style>
