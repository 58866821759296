<template>
  <div class="login-wrap animate__animated animate__fadeIn">

    <Tabs v-model="tabVal">
      <TabPane label="密码登录" name="password">
        <div class="login-form">
          <Spin size="large" fix v-if="loading"></Spin>
          <Form :model="loginForm" ref="passwordLogin" :rules="rules1" size="large">
            <FormItem label="" prop="phonenumber">
              <Input v-model="loginForm.phonenumber" placeholder="请输入手机号" size="large"></Input>
            </FormItem>
            <FormItem label="" prop="password">
              <Input type="password" password v-model="loginForm.password" placeholder="请输入登陆密码" size="large"
                     @keyup.enter.native="passwordLogin"></Input>
            </FormItem>
            <FormItem label="">
              <Checkbox v-model="loginForm.remember">十天内免登录</Checkbox>
            </FormItem>
            <FormItem label="">
              <Button long type="primary" size="large" @click="passwordLogin">登录</Button>
            </FormItem>
          </Form>
          <div class="login-footer">
            <div><a href="javascript:;" @click.prevent="$refs.ForgetPassword.show()">忘记密码？</a></div>
            <div><a href="javascript:;" @click="$router.push('/register')">注册账号</a></div>
          </div>
        </div>
      </TabPane>
      <TabPane label="短信登录" name="sms">
        <div class="login-form">
          <Form :model="loginForm" ref="smsLogin" :rules="rules2" size="large">
            <FormItem label="" prop="phonenumber">
              <Input v-model="loginForm.phonenumber" placeholder="请输入手机号" size="large"></Input>
            </FormItem>
            <FormItem label="" prop="code">
              <div class="flex">
                <Input v-model="loginForm.code" placeholder="请输入验证码" style="margin-right: 20px;"
                       @keyup.enter.native="loginByCode"></Input>
                <CountDown v-model="loginForm.recordId" :tel="loginForm.phonenumber" :templateType="1"></CountDown>
              </div>
            </FormItem>
            <FormItem label="">
              <Checkbox v-model="loginForm.remember">十天内免登录</Checkbox>
            </FormItem>
            <FormItem label="">
              <Button long type="primary" size="large" @click="loginByCode">登录</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
    <ForgetPassword ref="ForgetPassword"></ForgetPassword>
  </div>
</template>

<script>
import CountDown from '@/components/CountDown'
import ForgetPassword from './ForgetPassword'
import { mapGetters } from 'vuex'
export default {
  components: {
    CountDown,
    ForgetPassword
  },
  data () {
    return {
      loading: false,
      tabVal: 'password',
      loginForm: {},
      rules1: {
        phonenumber: [{ required: true, message: '请输入手机号' }],
        password: [{ required: true, message: '请输入登陆密码' }]
      },
      rules2: {
        phonenumber: [{ required: true, message: '请输入手机号' }],
        code: [{ required: true, message: '请输入验证码' }]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    isPerfect () {
      const { company, businessScope } = this.userInfo
      return company !== '' && businessScope !== ''
    }
  },
  methods: {
    passwordLogin () {
      this.$refs.passwordLogin.validate(valid => {
        if (valid) {
          this.loading = true
          const { phonenumber, password } = this.loginForm
          this.$store.dispatch('PASSWORD_LOGIN', { phonenumber, password }).then(res => {
            if (!this.isPerfect) this.$router.push('/usercenter')
          }).catch(res => {
            this.loading = false
          })
        }
      })
    },
    loginByCode () {
      this.$refs.smsLogin.validate(valid => {
        if (valid) {
          this.loading = true
          const { code, phonenumber, recordId } = this.loginForm
          this.$store.dispatch('CODE_LOGIN', { code, phonenumber, recordId }).then(res => {
            if (!this.isPerfect) this.$router.push('/usercenter')
          }).catch(res => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
    margin-top: 90px;
    width: 318px;
    height: 385px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 4px 4px 10px #66b1a0;
    border-radius: 10px;

    .login-form {
        padding-top: 20px;
        color: #8d8d8d !important;
    }
    .login-footer {
        display: flex;
        justify-content: space-between;
        & > div {
            flex: 1;
            text-align: center;
            a {
                color: #8d8d8d;
            }
        }
    }
}

.input-code {
    /deep/ .ivu-input {
        border-radius: 4px 0px 0 4px;
    }
}

/deep/ .ivu-tabs-bar {
    margin-left: 10px;
    margin-right: 10px;
}

/deep/ .ivu-tabs-bar {
    border-bottom: none !important;
}
/deep/ .ivu-tabs-nav {
    width: 100%;
}
/deep/ .ivu-tabs-tab {
    width: 50%;
    text-align: center;
}
/deep/ .ivu-input {
    border: 1px solid transparent;
    background: #e5e5e5;
    border-radius: 5px;
    color: #8d8d8d !important;
    font-size: 14px;
}
/deep/ .ivu-checkbox-inner {
    border: 1px solid transparent;
    background: #e5e5e5;
}
</style>
