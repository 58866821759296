<template>
  <div>
    <Form :model="form" ref="Form" :rules="rules">
      <FormItem style="margin-bottom:10px;">
        <Input v-model="form.phonenumber" placeholder="请输入手机号码"></Input>
      </FormItem>
      <FormItem prop="code">
        <Input v-model="form.code" placeholder="请输入验证码"
               style="display: inline-block;margin-right:20px;width:calc(100% - 150px)"></Input>
        <CountDown v-model="form.recordId" :tel="form.phonenumber" :templateType="2"></CountDown>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import CountDown from '@/components/CountDown'
import { validataCode } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    CountDown
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data () {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [{ required: true, message: '请输入验证码' }]
      }
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            validataCode(this.form).then(res => {
              if (res.isSuccess) {
                return resolve(this.form)
              } else {
                this.$Message.error(res.msg)
              }
            })
          } else {
            return reject(valid)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
