<template>
  <Modal v-model="visible" footer-hide @on-cancel="cancel" width="440px" class-name="passowrd-dialog" :mask-closable="false">
    <div slot="header" class=""> {{title}}</div>
    <Steps :current="current" class="my-steps" size="small">
      <Step title="验证身份 ">
      </Step>
      <Step title="输入新密码"></Step>
      <Step title="完成"></Step>
    </Steps>
    <div style="padding:20px 0 0;">
      <ValidatePassword v-show="current === 0" ref="Step0"></ValidatePassword>
      <UpdatePassword v-show="current === 1" ref="Step1"></UpdatePassword>
      <Success v-show="current === 2" ref="Step2"></Success>
      <div v-if="current < 2"><Button type="primary" long @click="next">下一步</Button></div>
      <div v-else><Button type="primary" long @click="logout">登陆</Button></div>
    </div>
  </Modal>
</template>

<script>
import ValidatePassword from './Step1'
import UpdatePassword from './Step2'
import Success from './Step3'
import { resetPwd } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    ValidatePassword,
    UpdatePassword,
    Success
  },
  data () {
    return {
      visible: false,
      current: 0,
      form: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    title () {
      let title = '为确保是您本人操作，请完成短信验证'
      switch (title) {
        case 0:
          title = '为确保是您本人操作，请完成短信验证'
          break
        case 1:
          title = '请输入新密码完成修改'
          break
        case 2:
          title = '重置完成 '
          break

        default:
          break
      }
      return title
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    ok () {

    },
    cancel () {
      this.$emit('cancel')
    },
    next () {
      console.log(this.current)
      if (this.current === 0) {
        this.$refs['Step' + this.current].validate().then(res => {
          this.form = res
          this.current++
        })
      }
      if (this.current === 1) {
        this.$refs['Step' + this.current].validate().then(res => {
          resetPwd({ phonenumber: this.userInfo.phonenumber, newPwd: res, ...this.form }).then(res => {
            if (res.isSuccess) {
              this.current++
            }
          })
        })
      }
    },
    logout () {
      this.$store.dispatch('LOGOUT').then(res => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
