<template>
  <div class="iframe-main">
    <div class="iframe-dom"></div>
  </div>
</template>
<script>
export default {
  name: 'iframeComponent',
  data () {
    return {
      iframe: null
    }
  },
  watch: {},
  mounted () {
    this.init()
    window.addEventListener('message', this.receiveData)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveData)
  },
  methods: {
    /**
     * @description: 初始化iframe类
     * @return {*}
     */
    init () {
      this.iframe = new this.$bxhIframe({
        production: false,
        elCls: 'iframe-dom',
        prefixCls: 'iframePage',
        name: 'models'
      })
    },
    /**
     * @description: iframe通信回调方法
     * @param {*} event
     * @return {*}
     */
    receiveData (event) {
      if (event) {
        try {
          if (typeof event.data === 'string') {
            const { event: e, name, params, preDesignParams } = JSON.parse(
              event.data
            )
            if (e === 'needLogin') {
              this.iframe.preDesign({ ...preDesignParams, token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOiJ6aGVuZ3Rvbmd5dW4iLCJtYWluVWlkIjoiZXhqMmg0ajZpbCIsImlhdCI6MTY2MTQ5Njg0MCwiZXhwIjoxNjYyMTAxNjQwfQ.JgS-RIZQ0nyfmvzdtndSG-3usuDTQt9gc_gkaq6D7cc' })
            } else if (e === 'viewDetails') {
              this.iframe.render({ name, params })
            } else if (e === 'viewDetails') {
              this.iframe.render({ name, params })
            } else if (e === 'startDesign') {
              this.iframe.render({ params: { id: params.id, token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOiJ6aGVuZ3Rvbmd5dW4iLCJtYWluVWlkIjoiZXhqMmg0ajZpbCIsImlhdCI6MTY2MTQ5Njg0MCwiZXhwIjoxNjYyMTAxNjQwfQ.JgS-RIZQ0nyfmvzdtndSG-3usuDTQt9gc_gkaq6D7cc' }, name: 'design' })
            } else if (e === 'logoutSuccess') {
              console.log('logoutSuccess')
            } else if (e === 'reloadParams') {
              console.log('reloadParams')
            } else if (e === 'saveSuccess') {
              console.log('saveSuccess')
            } else if (e === 'saveFail') {
              console.log('saveFail')
            } else if (e === 'loadSuccess') {
              console.log('loadSuccess')
            } else if (e === 'toPreview3d') {
              console.log('手机端编辑器进入3d页面回调')
            } else if (e === 'toEditor') {
              console.log('手机端从3d页面进入编辑器回调')
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>
<style scoped>
.iframe-main {
    width: 100%;
    height: 100%;
}
.iframe-dom {
    height: calc(100vh);
    position: relative;
}
</style>
